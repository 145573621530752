import { get } from '@/utils/requestWrapper';

function getByEndpoint<Result>(endpoint: string) {
    const url = endpoint.replace(/^(\/api)/, '');
    return get<Result>({ url });
}

function getMultipleByEndpoints<Result>(endpoints: string[]) {
    const promiseArray = endpoints.map((endpoint) => getByEndpoint<Result>(endpoint));
    return Promise.allSettled(promiseArray)
        .then((results) =>
            results
                .filter((result) => result.status === 'fulfilled')
                .map((result) => (result as PromiseFulfilledResult<Result>).value));
}

export default {
    getByEndpoint,
    getMultipleByEndpoints,
};
