export enum RouteNames {
    Login = 'Login',
    Menu = 'Menu',
    NotFound = 'NotFound',

    Test = 'Test',

    New_Sale = 'New_Sale',
    New_Sale_Cart = 'New_Sale_Cart',
    New_Sale_Cart_Select_List = 'New_Sale_Cart_Select_List',
    New_Sale_Cart_Select_Customer = 'New_Sale_Cart_Select_Customer',
    New_Sale_Cart_Customer_Detail = 'New_Sale_Cart_Customer_Detail',
    New_Sale_Cart_Customer_Detail_Orders = 'New_Sale_Customer_Detail_orders',
    New_Sale_Cart_Customer_Detail_New_Address = 'New_Sale_Cart_Customer_Detail_New_Address',
    New_Sale_Cart_Customer_New = 'New_Sale_Cart_Customer_New',
    New_Sale_Checkout = 'New_Sale_Checkout',
    Overview_Sales = 'Overview_Sales',
    Invoices = 'Invoices',
    Inventory_Import = 'Inventory_Import',
    Return_Request = 'Return_Request',
}
