import type { CustomerWithAddress } from '@/types/customer';
import type { ApiSearchParams } from '@/types/api';
import type { Notification, PaymentMethodCode, PosUnit, Voucher } from '@/types/common';
import type { Wishlist, WishlistTheme } from '@/types/wishlist';
import type { Invoice } from '@/types/invoice';

export enum CheckoutState {
    Cart = 'cart',
    Addressed = 'addressed',
    PaymentSelected = 'payment_selected',
    ShippingSkipped = 'shipping_skipped',
    Completed = 'completed',
}

export enum PaymentState {
    Cart = 'cart',
    Paid = 'paid',
}

export enum OrderAdjustmentType {
    ORDER_ITEM_PROMOTION = 'order_item_promotion',
    ORDER_PROMOTION = 'order_promotion',
    ORDER_SHIPPING_PROMOTION = 'order_shipping_promotion',
    ORDER_UNIT_PROMOTION = 'order_unit_promotion',
    POS_ITEM_DISCOUNT = 'pos_item_discount',
    SHIPPING = 'shipping',
    TAX = 'tax',
}

export enum ReturnRequestStatus {
    NEW = 'new',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
}

export interface NewOrder extends Order {
    channel: string;
    state: string;
}

export interface Order {
    payments: Payment[];
    shipments: Shipment[];
    currencyCode: string;
    localeCode: string;
    checkoutState: CheckoutState;
    paymentState: PaymentState;
    shippingState: string;
    tokenValue: string;
    id: number;
    items: OrderItem[];
    itemsTotal: number;
    total: number;
    itemsSubtotal: number;
    taxTotal: number;
    taxExcludedTotal: number;
    taxIncludedTotal: number;
    shippingTotal: number;
    orderPromotionTotal: number;
    billingAddress?: CustomerWithAddress;
    shippingAddress?: CustomerWithAddress;
    invoices: Invoice[];
    createdAt: string;
    updatedAt: string;
    checkoutCompletedAt: string;
    posUnit: Omit<PosUnit, 'store'>;
    notifications?: Notification[];
    customer?: string;
    number?: string;
}

export interface OrderItem {
    id: number;
    productName: string;
    quantity: number;
    unitPrice: number;
    originalUnitPrice: number;
    total: number;
    discountedUnitPrice: number;
    subtotal: number;
    posDiscountPercentage?: number;
    posDiscountedUnitPrice?: number;
    variant: OrderItemVariant;
    variantImage?: string;
    voucher?: boolean;
    voucherDetails?: Voucher;
    wishlist?: OrderItemWishlist;
    notifications?: Notification[];
}

export interface OrderItemVariant {
    inStock: boolean;
    lowestPriceBeforeDiscount?: number;
    name: string;
    optionValues: OptionValue[];
    originalPrice: number;
    price: number;
    code: string;
    virtual?: boolean;
}

export interface OptionValue {
    code: string;
    id: number;
    option: {
        id: number;
        name: string;
    }
    value: string;
}

export interface OrderItemWishlist extends Pick<Wishlist, 'id' | 'code' | 'createdAt' | 'title'> {
    theme: WishlistTheme;
}

export interface OrderAdjustment {
    id: number;
    type: OrderAdjustmentType;
    label: string;
    amount: number;
    neutral: boolean;
}

export interface Payment {
    id: number;
    method: string | [];
    state: string;
    amount: number;
}

export interface Shipment {
    id: number;
    method: string;
}

export interface OrderWithInvoice extends Omit<Order, 'invoices'> {
    invoice?: Invoice;
}

export interface GetOrderDetailsPayload {
    tokenValue: string;
}

export interface CancelOrderPayload extends GetOrderDetailsPayload {}

export interface AddItemToOrderPayload extends GetOrderDetailsPayload {
    productVariant: string;
    quantity?: number;
}

export interface RemoveItemFromOrderPayload extends GetOrderDetailsPayload {
    orderItemId: number;
}

export interface UpdateItemQuantity {
    orderItemId: number;
    quantity: number;
}

export type UpdateItemQuantityPayload = UpdateItemQuantity & GetOrderDetailsPayload;

export interface UpdateItemDiscountPayload extends GetOrderDetailsPayload {
    orderItemId: number;
    discount: number;
}

export interface UpdateItemWishlistPayload extends GetOrderDetailsPayload {
    orderItemId: number;
    wishlistCode: string;
}

export type UpdateVoucherDetails = Omit<
    Voucher,
    'id' | 'restAmount' | 'enabled' | 'used' | 'currencyCode' | 'title'
> & {
    title?: string;
};

export interface UpdateItemVoucherDetailsPayload extends GetOrderDetailsPayload {
    orderItemId: number;
    voucherDetails: UpdateVoucherDetails;
}

export interface UpdateOrderPayload extends GetOrderDetailsPayload {
    email?: string;
    billingAddress?: Partial<CustomerWithAddress>;
    shippingAddress?: CustomerWithAddress;
    couponCode?: string;
}

export interface GetOrdersPayload extends Omit<ApiSearchParams, 'query'> {
    customer?: string | string[];
    'createdAt[after]'?: string;
    'createdAt[strictly_after]'?: string;
    'createdAt[before]'?: string;
    'createdAt[strictly_before]'?: string;
    'updatedAt[after]'?: string;
    'updatedAt[strictly_after]'?: string;
    'updatedAt[before]'?: string;
    'updatedAt[strictly_before]'?: string;
    'checkoutCompletedAt[after]'?: string;
    'checkoutCompletedAt[strictly_after]'?: string;
    'checkoutCompletedAt[before]'?: string;
    'checkoutCompletedAt[strictly_before]'?: string;
    posUnit?: string | string[];
}

export interface PaymentPayload<PaymentData> {
    orderTokenValue: string;
    paymentMethod: PaymentMethodCode;
    paymentData: PaymentData;
}

export interface CashPaymentData {
    amount: number;
    correction?: number;
}

export interface VoucherPaymentData {
    voucherCode: string;
}

export interface OtherPaymentData {
    amount: number;
    type: string;
}

export type OfflinePaymentData = OtherPaymentData;

export type VoucherPaymentPayload = PaymentPayload<VoucherPaymentData>;

export interface PosSalesStat {
    id: number;
    date: string;
    posUnit: {
        id: string;
    };
    salesTotal: number;
}

export interface GetPosSalesStatsPayload extends Omit<ApiSearchParams, 'query'> {
    'date[after]'?: string;
    'date[strictly_after]'?: string;
    'date[before]'?: string;
    'date[strictly_before]'?: string;
    'store'?: string;
}

export interface ReturnRequest {
    id: number;
    order: Order;
    status: ReturnRequestStatus;
    voucher?: string;
    creditNote?: Pick<Invoice, 'id' | 'type'>;
}

export interface ReturnItem {
    id: number;
    returnRequest: string;
    orderItem: Pick<OrderItem, 'id'>;
    quantity: number;
    total: number;
    taxTotal: number;
}

export interface AddReturnItemPayload {
    orderItemId: number;
    returnRequestId: number;
    quantity: number;
}

export interface UpdateReturnItemPayload {
    returnRequestId: number;
    returnItemId: number;
    quantity: number;
}

export interface DeleteReturnItemPayload {
    returnRequestId: number;
    returnItemId: number;
}

export interface CancelReturnRequestPayload extends Pick<ReturnRequest, 'id'> {}
export interface CompleteReturnRequestPayload extends Pick<ReturnRequest, 'id'> {}
