import { RouteNames } from '@/router/routeNames';
import NewSaleSvg from '@/assets/images/cart.svg?component';
import ShoppingBagSvg from '@/assets/images/shopping-bag.svg?component';
import InvoicesSvg from '@/assets/images/invoices.svg?component';
import InventorySvg from '@/assets/images/box.svg?component';
import ReturnSvg from '@/assets/images/return.svg?component';
import type { FunctionalComponent } from 'vue';

interface MenuItem {
    translationKey: string;
    routeName: RouteNames;
    svg: FunctionalComponent;
}

const TRANSLATION_PREFIX = 'menu';

export const MENU_ITEMS: MenuItem[] = [
    {
        translationKey: `${TRANSLATION_PREFIX}.newSale`,
        routeName: RouteNames.New_Sale_Cart,
        svg: NewSaleSvg,
    },
    {
        translationKey: `${TRANSLATION_PREFIX}.overviewSales`,
        routeName: RouteNames.Overview_Sales,
        svg: ShoppingBagSvg,
    },
    {
        translationKey: `${TRANSLATION_PREFIX}.invoices`,
        routeName: RouteNames.Invoices,
        svg: InvoicesSvg,
    },
    {
        translationKey: `${TRANSLATION_PREFIX}.inventoryImport`,
        routeName: RouteNames.Inventory_Import,
        svg: InventorySvg,
    },
    {
        translationKey: `${TRANSLATION_PREFIX}.returnRequest`,
        routeName: RouteNames.Return_Request,
        svg: ReturnSvg,
    },
];
