import type { ApiSearchParams, WithContext } from '@/types/api';
import type { Order } from '@/types/order';

export enum InvoiceType {
    Unknown = '',
    Invoice = 'invoice',
    Receipt = 'receipt',
    DailyInvoice = 'daily_invoice',
    Credit = 'credit',
}

export interface Invoice<OrderType = string | WithContext<Order>> {
    id: string;
    issuedAt: string;
    number: string;
    order?: OrderType;
    total: number;
    currencyCode: string;
    type: InvoiceType;
    invoicedBy?: Invoice;
}

export interface GetInvoicesPayload extends Omit<ApiSearchParams, 'query'> {
    'issuedAt[after]'?: string;
    'issuedAt[strictly_after]'?: string;
    'issuedAt[before]'?: string;
    'issuedAt[strictly_before]'?: string;
    'order.posUnit'?: string | string[];
    'order.posUnit.store'?: string | string[];
    'order.customer'?: string;
    'number'?: string;
    'type'?: string | string[];
    'search'?: string;
}

export interface InvoicePdf {
    blob: Blob;
    height?: string;
}
