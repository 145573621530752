import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { type AsyncEntity, AsyncStatus, type CollectionWithContext } from '@/types/api';
import { asyncFetchHandler, resetAsyncEntity } from '@/utils/asyncEntity';
import CustomersService from '@/services/CustomersService';
import type { Invoice } from '@/types/invoice';
import type {
    Customer,
    AddAddressPayload,
    AddCustomerPayload,
    CustomerWithAddress,
    UpdateCustomerPayload,
    CustomerSearchResult,
} from '@/types/customer';
import { useNewSaleStore } from '@/stores';
import InvoiceService from '@/services/InvoiceService';
import CommonService from '@/services/CommonService';

export const useCustomerStore = defineStore('customer', () => {
    const customersSearchEntity: AsyncEntity<CustomerSearchResult[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
        abortController: new AbortController(),
    });

    const customerDetail: AsyncEntity<Customer> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const customerInvoices: AsyncEntity<CollectionWithContext<Invoice>> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const newCustomer: AsyncEntity<Customer> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const newAddress: AsyncEntity<CustomerWithAddress & CollectionWithContext> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const addressDetail: AsyncEntity<CustomerWithAddress> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const customerSearchQuery = ref<string>('');

    function searchCustomers(query: string) {
        if (customersSearchEntity.status === AsyncStatus.Busy && customersSearchEntity?.abortController?.abort) {
            customersSearchEntity.abortController.abort();
        }

        customersSearchEntity.abortController = new AbortController();
        return asyncFetchHandler({
            asyncEntity: customersSearchEntity,
            service: CustomersService.getCustomers,
        })({
            query,
            itemsPerPage: 10,
            signal: customersSearchEntity.abortController.signal,
        });
    }

    function getCustomerDetail(customerId: number) {
        return asyncFetchHandler({
            asyncEntity: customerDetail,
            service: CustomersService.getCustomerDetail,
            resetDataOnTrigger: true,
        })(customerId);
    }

    function getCustomerDetailByIri(iri: string) {
        return asyncFetchHandler({
            asyncEntity: customerDetail,
            service: CommonService.getByEndpoint,
            resetDataOnTrigger: true,
        })(iri);
    }

    function updateCustomer(customer: UpdateCustomerPayload) {
        return asyncFetchHandler({
            asyncEntity: customerDetail,
            service: CustomersService.updateCustomer,
        })(customer);
    }

    function addNewCustomer(payload: AddCustomerPayload) {
        return asyncFetchHandler({
            asyncEntity: newCustomer,
            service: CustomersService.addCustomer,
        })(payload);
    }

    function addNewCustomerAddress(payload: AddAddressPayload) {
        return asyncFetchHandler({
            asyncEntity: newAddress,
            service: CustomersService.addAddress,
        })(payload);
    }

    function addNewCustomerWithAddress(payload: AddAddressPayload & AddCustomerPayload) {
        return addNewCustomerAddress(payload)
            .then((address) => {
                if (address instanceof Error) {
                    return new Error();
                }

                return addNewCustomer({
                    ...payload,
                    defaultAddress: address['@id'],
                });
            });
    }

    function addDefaultAddressToCustomer({ customerId, address }: { customerId: number; address: AddAddressPayload; }) {
        return addNewCustomerAddress(address)
            .then((address) => {
                if (address instanceof Error) {
                    return new Error();
                }

                const { linkedCustomer } = useNewSaleStore();

                return updateCustomer({
                    id: customerId,
                    defaultAddress: address['@id'],
                }).then((customer) => {
                    if (
                        !(customer instanceof Error)
                        && linkedCustomer?.customer
                        && linkedCustomer.customer.id && customer.id
                    ) {
                        linkedCustomer.address = address;
                    }
                    return customer;
                });
            });
    }

    function updateAddress(address: CustomerWithAddress) {
        return asyncFetchHandler({
            asyncEntity: addressDetail,
            service: CustomersService.updateAddress,
        })(address);
    }

    function getAddressDetail(id: number) {
        return asyncFetchHandler({
            asyncEntity: addressDetail,
            service: CustomersService.getAddressDetail,
            resetDataOnTrigger: true,
        })(id);
    }

    function getInvoices({ page, itemsPerPage }: {
        page: number;
        itemsPerPage: number;
    }) {
        const customerId = customerDetail.data?.id.toString();

        if (!customerId) {
            return Promise.reject(new Error('Customer ID is missing'));
        }

        return asyncFetchHandler({
            asyncEntity: customerInvoices,
            service: InvoiceService.getInvoices,
        })({
            ['order.customer']: customerId,
            page,
            itemsPerPage,
        });
    }

    function resetCustomerSearch() {
        resetAsyncEntity(customersSearchEntity);
    }

    function resetCustomerInvoices() {
        resetAsyncEntity(customerInvoices);
    }

    return {
        searchCustomers, customerSearchQuery, customersSearchEntity, resetCustomerSearch,
        newCustomer, addNewCustomer, newAddress, addNewCustomerWithAddress, addNewCustomerAddress,
        addDefaultAddressToCustomer,
        customerDetail, getCustomerDetail, getCustomerDetailByIri,
        addressDetail, getAddressDetail, updateAddress,
        customerInvoices, getInvoices, resetCustomerInvoices,
    };
}, {
    persist: true,
});
