export enum AsyncStatus {
    Initial = 'initial',
    Busy = 'busy',
    Success = 'success',
    Error = 'error',
}

export interface ApiError<ErrorResponseData extends object = ApiMessageError> {
    status: number;
    originatingRequest: {
        method?: string;
        url?: string;
    };
    wasCancelled: boolean;
    response: ErrorResponseData;
}

export interface ApiMessageError {
    message: string;
}

export interface ApiPlatformError {
    type: string;
    title: string;
    detail: string;
    violations?: ApiPlatformViolation[];
}

export interface ApiPlatformViolation {
    propertyPath: string;
    message: string;
    code: string;
}

export interface ApiPlatformHydraError {
    'hydra:title': string;
    'hydra:description': string;
    violations?: ApiPlatformViolation[];
}

export type ApiErrorResponse = ApiMessageError | ApiPlatformError | ApiPlatformHydraError;

export interface AsyncEntity<Data = unknown, Error = ApiError<ApiErrorResponse>> {
    data: Data | null;
    error: null | string | Error;
    status: AsyncStatus;
    abortController?: AbortController;
}

export interface ApiSearchParams {
    query?: string;
    page?: number;
    itemsPerPage?: number;
}

export interface IUrlParams {
    [key: string]: number | string | boolean;
}

export type WithContext<Type extends object = Record<string, never>> = Type & {
    readonly '@id': string;
    readonly '@type': string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CollectionWithContext<MemberType = any> extends Pick<WithContext, '@id' | '@type'> {
    'hydra:member': MemberType[];
    'hydra:totalItems': number;
}
