<script setup lang="ts">
import { computed } from 'vue';
import { MENU_ITEMS } from '@/config/menu.config';

const gridSize = computed(() => MENU_ITEMS.length >= 4 ? 4 : MENU_ITEMS.length);
const lines = computed(() => Math.ceil(MENU_ITEMS.length / gridSize.value));
</script>

<template>
    <h1 class="title">
        <Translate msg="menu.title" />
    </h1>
    <nav class="main-nav">
        <ul
            class="main-nav__list"
            :class="`--rows-${lines}`"
            :style="{
                gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
            }"
        >
            <li
                v-for="item in MENU_ITEMS"
                :key="item.translationKey"
            >
                <MenuTile
                    :route-name="item.routeName"
                    :translation-key="item.translationKey"
                    :svg="item.svg"
                />
            </li>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
.title {
    margin: 0;
    font-size: 3rem;
    font-weight: var(--font-weight-medium);
    text-align: center;
}

.main-nav {
    block-size: 100%;
}

.main-nav__list {
    --max-tile-icon-size: clamp(4rem, -46rem + 100vh, 16rem);

    @extend %reset-list;

    display: grid;
    gap: 6rem;
    align-items: stretch;

    max-inline-size: 118rem;
    margin: 5rem auto 4rem;

    &.--rows-2 {
        --max-tile-icon-size: clamp(4rem, -34.6rem + 52.2vh, 16rem);

        @media (max-height: 740px) {
            --menu-tile-label-display: none;
            --max-tile-icon-size: clamp(4rem, -25rem + 50vh, 12rem);

            gap: 3rem;
        }
    }

    @media (max-height: 480px) {
        --max-tile-icon-size: clamp(4rem, -21.8rem + 60vh, 7rem);
        --menu-tile-label-display: none;
    }
}
</style>
