
import { AsyncStatus, type AsyncEntity } from '@/types/api';
import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import type { ReturnItem, ReturnRequest } from '@/types/order';
import { asyncFetchHandler, resetAsyncEntity } from '@/utils/asyncEntity';
import OrderService from '@/services/OrderService';
import type { Invoice } from '@/types/invoice';

export const useReturnRequestStore = defineStore('returnRequest', () => {
    const returnRequestEntity: AsyncEntity<ReturnRequest> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const returnItemEntity: AsyncEntity<ReturnItem|void> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const returnItemsEntity: AsyncEntity<ReturnItem[]> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const cancelReturnRequestEntity: AsyncEntity<ReturnRequest> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const completeReturnRequestEntity: AsyncEntity<ReturnRequest> = reactive({
        status: AsyncStatus.Initial,
        error: null,
        data: null,
    });

    const selectedInvoice = ref<Invoice | null>(null);
    const returnItems = ref<ReturnItem[]>([]);
    const updatingOrderItemId = ref<number | null>(null);

    function getReturnRequest(returnRequestId: number) {
        return asyncFetchHandler({
            asyncEntity: returnRequestEntity,
            service: OrderService.getReturnRequest,
        })(returnRequestId);
    }

    function createReturnRequest(orderTokenValue: string) {
        return asyncFetchHandler({
            asyncEntity: returnRequestEntity,
            service: OrderService.createNewReturnRequest,
        })({ orderTokenValue });
    }

    function updateReturnItemQuantity({ orderItemId, quantity }: {
        orderItemId: number;
        quantity: number;
    }) {
        updatingOrderItemId.value = orderItemId;
        const orderItemInReturnRequest = returnItems.value.find((item) => item.orderItem.id === orderItemId);
        const updatePromise = orderItemInReturnRequest
            ? quantity <= 0
                ? removeReturnItem({ returnItemId: orderItemInReturnRequest.id })
                : updateReturnItem({
                    returnItemId: orderItemInReturnRequest.id,
                    quantity,
                })
            : quantity > 0
                ? createReturnItem({ orderItemId, quantity })
                : Promise.resolve(null); // do nothing if quantity is 0 and return item is not in return request yet

        return updatePromise
            .then((response) => {
                if (response instanceof Error) {
                    return response;
                }

                if (!response?.id) {
                    returnItems.value = returnItems.value.filter((item) => item.orderItem.id !== orderItemId);
                    return response;
                }

                if (orderItemInReturnRequest) {
                    returnItems.value = returnItems.value.map((item) => {
                        if (item.orderItem.id === orderItemId) {
                            return response;
                        }

                        return item;
                    });
                } else {
                    returnItems.value.push(response);
                }

                return response;
            })
            .finally(() => {
                updatingOrderItemId.value = null;
            });
    }

    function getReturnItems(returnRequestId: number) {
        return asyncFetchHandler({
            asyncEntity: returnItemsEntity,
            service: OrderService.getReturnItems,
        })(returnRequestId)
            .then((response) => {
                if (response instanceof Error) {
                    return response;
                }

                returnItems.value = response;

                return response;
            });
    }

    function createReturnItem({ orderItemId, quantity }: { orderItemId: number; quantity: number }) {
        if (!returnRequestEntity.data) {
            throw new Error('Return request is not created yet');
        }

        return asyncFetchHandler({
            asyncEntity: returnItemEntity,
            service: OrderService.createNewReturnItem,
        })({
            orderItemId,
            returnRequestId: returnRequestEntity.data.id,
            quantity,
        });
    }

    function updateReturnItem({ returnItemId, quantity }: { returnItemId?: number; quantity: number }) {
        if (!returnRequestEntity.data) {
            throw new Error('Return request is not created yet');
        }

        if (!returnItemId) {
            throw new Error('Return item id is required');
        }

        return asyncFetchHandler({
            asyncEntity: returnItemEntity,
            service: OrderService.updateReturnItemQuantity,
        })({
            returnRequestId: returnRequestEntity.data.id,
            returnItemId,
            quantity,
        });
    }

    function removeReturnItem({ returnItemId }: { returnItemId: number }) {
        if (!returnRequestEntity.data) {
            throw new Error('Return request is not created yet');
        }

        return asyncFetchHandler({
            asyncEntity: returnItemEntity,
            service: OrderService.deleteReturnItem,
        })({
            returnRequestId: returnRequestEntity.data.id,
            returnItemId,
        });
    }

    function cancelReturnRequest() {
        if (!returnRequestEntity.data) {
            return Promise.reject(new Error('Return request is not created yet'));
        }

        return asyncFetchHandler({
            asyncEntity: cancelReturnRequestEntity,
            service: OrderService.cancelReturnRequest,
        })({ id: returnRequestEntity.data.id })
            .then((response) => {
                if (!(response instanceof Error)) {
                    resetReturnRequestData();
                }

                return response;
            });
    }

    function completeReturnRequest() {
        if (!returnRequestEntity.data) {
            return Promise.reject(new Error('Return request is not created yet'));
        }

        return asyncFetchHandler({
            asyncEntity: completeReturnRequestEntity,
            service: OrderService.completeReturnRequest,
        })({ id: returnRequestEntity.data.id });
    }

    function resetReturnRequestData() {
        resetAsyncEntity(returnRequestEntity);
        returnItems.value = [];
        setSelectedInvoice(null);
    }

    function setSelectedInvoice(invoice: Invoice | null) {
        selectedInvoice.value = invoice;
    }

    return {
        returnRequestEntity, getReturnRequest, createReturnRequest,
        selectedInvoice, setSelectedInvoice,
        returnItemEntity, returnItemsEntity, returnItems, getReturnItems, updatingOrderItemId,
        updateReturnItemQuantity,
        cancelReturnRequestEntity, cancelReturnRequest,
        completeReturnRequestEntity, completeReturnRequest,
        resetReturnRequestData,
    };
}, {
    persist: {
        paths: ['returnRequestEntity', 'selectedInvoice'],
    },
});
