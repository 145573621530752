import { get, patch, post, remove, put } from '@/utils/requestWrapper';
import type {
    AddItemToOrderPayload, AddReturnItemPayload,
    CancelOrderPayload, CancelReturnRequestPayload, CompleteReturnRequestPayload, DeleteReturnItemPayload,
    GetOrderDetailsPayload,
    GetPosSalesStatsPayload,
    NewOrder,
    Order,
    OrderAdjustment,
    Payment,
    PaymentPayload,
    PosSalesStat,
    RemoveItemFromOrderPayload, ReturnItem, ReturnRequest,
    UpdateItemDiscountPayload,
    UpdateItemQuantityPayload,
    UpdateItemVoucherDetailsPayload,
    UpdateItemWishlistPayload,
    UpdateOrderPayload, UpdateReturnItemPayload,
} from '@/types/order';

const URL = {
    ORDERS: '/pos/orders',
    ORDER_NEW: '/shop/orders',
    ORDER_DETAIL: '/shop/orders/:tokenValue',
    ORDER_CANCEL: '/pos/orders/:tokenValue/cancel',
    ORDER_ITEMS: '/shop/orders/:tokenValue/items',
    ORDER_ITEM: '/shop/orders/:tokenValue/items/:orderItemId',
    ORDER_ITEM_DISCOUNT: '/pos/orders/:tokenValue/items/:orderItemId/discount',
    ORDER_ITEM_WISHLIST: '/shop/orders/:tokenValue/items/:orderItemId/wishlist',
    ORDER_ADJUSTMENTS: '/shop/orders/:tokenValue/adjustments',
    VOUCHER_DETAILS: '/shop/orders/:tokenValue/items/:orderItemId/voucher-details',
    PAYMENT: '/pos/payments',
    POS_SALES_STATS: '/pos/sales-stats',
    RETURN_REQUEST: '/pos/return-requests',
    RETURN_REQUEST_DETAIL: '/pos/return-requests/:id',
    RETURN_ITEMS: '/pos/return-requests/:returnRequestId/return-items',
    RETURN_ITEM: '/pos/return-requests/:returnRequestId/return-items/:id',
    RETURN_REQUEST_CANCEL: '/pos/return-requests/:id/cancel',
    RETURN_REQUEST_COMPLETE: '/pos/return-requests/:id/complete',
};

function createNewOrder() {
    return post<NewOrder>({
        url: URL.ORDER_NEW,
    });
}

function getOrderDetail({ tokenValue }: GetOrderDetailsPayload) {
    return get<Order>({
        url: URL.ORDER_DETAIL,
        pathParams: {
            tokenValue,
        },
    });
}

function cancelOrder({ tokenValue }: CancelOrderPayload) {
    return patch<Order>({
        url: URL.ORDER_CANCEL,
        pathParams: {
            tokenValue,
        },
    });
}

function addItemToOrder({ tokenValue, productVariant, quantity = 1 }: AddItemToOrderPayload) {
    return post<Order>({
        url: URL.ORDER_ITEMS,
        pathParams: {
            tokenValue,
        },
        body: {
            productVariant,
            quantity,
        },
    });
}

function deleteItemFromOrder({ tokenValue, orderItemId }: RemoveItemFromOrderPayload) {
    return remove<Order>({
        url: URL.ORDER_ITEM,
        pathParams: {
            tokenValue,
            orderItemId,
        },
    });
}

function updateItemQuantity({ tokenValue, orderItemId, quantity }: UpdateItemQuantityPayload) {
    return patch<Order>({
        url: URL.ORDER_ITEM,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            quantity,
        },
    });
}

function updateItemDiscount({ tokenValue, orderItemId, discount }: UpdateItemDiscountPayload) {
    return patch<Order>({
        url: URL.ORDER_ITEM_DISCOUNT,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            percentage: discount,
        },
    });
}

function updateItemWishlist({ tokenValue, orderItemId, wishlistCode }: UpdateItemWishlistPayload) {
    return patch<Order>({
        url: URL.ORDER_ITEM_WISHLIST,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            wishlistCode,
        },
    });
}

function updateVoucherDetail({ tokenValue, orderItemId, voucherDetails }: UpdateItemVoucherDetailsPayload) {
    return patch<Order>({
        url: URL.VOUCHER_DETAILS,
        pathParams: {
            tokenValue,
            orderItemId,
        },
        body: {
            ...voucherDetails,
            expiresAt: typeof voucherDetails.expiresAt === 'string'
                ? voucherDetails.expiresAt
                : voucherDetails.expiresAt.toISOString(),
        },
    });
}

function updateOrder({ tokenValue, ...body }: UpdateOrderPayload) {
    return put<Order>({
        url: URL.ORDER_DETAIL,
        pathParams: {
            tokenValue,
        },
        body,
    });
}

function getOrderAdjustments({ tokenValue }: GetOrderDetailsPayload) {
    return get<OrderAdjustment[]>({
        url: URL.ORDER_ADJUSTMENTS,
        pathParams: {
            tokenValue,
        },
    });
}

function createPayment<T>(body: PaymentPayload<T>) {
    return post<Payment>({
        url: URL.PAYMENT,
        body,
    });
}

function getPosSalesStats(params: GetPosSalesStatsPayload) {
    return get<PosSalesStat[]>({
        url: URL.POS_SALES_STATS,
        params,
    });
}

function createNewReturnRequest({ orderTokenValue }: { orderTokenValue: string }) {
    return post<ReturnRequest>({
        url: URL.RETURN_REQUEST,
        body: { orderTokenValue },
    });
}

function getReturnRequest(id: number) {
    return get<ReturnRequest>({
        url: URL.RETURN_REQUEST_DETAIL,
        pathParams: { id },
    });
}

function getReturnItems(returnRequestId: number) {
    return get<ReturnItem[]>({
        url: URL.RETURN_ITEMS,
        pathParams: { returnRequestId },
    });
}

function createNewReturnItem({ returnRequestId, ...body }: AddReturnItemPayload) {
    return post<ReturnItem>({
        url: URL.RETURN_ITEMS,
        body: body,
        pathParams: { returnRequestId },
    });
}

function updateReturnItemQuantity({ returnRequestId, returnItemId, ...body }: UpdateReturnItemPayload) {
    return patch<ReturnItem>({
        url: URL.RETURN_ITEM,
        pathParams: {
            returnRequestId,
            id: returnItemId,
        },
        body,
    });
}

function deleteReturnItem({ returnItemId, returnRequestId }: DeleteReturnItemPayload) {
    return remove<void>({
        url: URL.RETURN_ITEM,
        pathParams: {
            returnRequestId,
            id: returnItemId,
        },
    });
}

function cancelReturnRequest({ id }: CancelReturnRequestPayload) {
    return patch<ReturnRequest>({
        url: URL.RETURN_REQUEST_CANCEL,
        pathParams: {
            id,
        },
    });
}

function completeReturnRequest({ id }: CompleteReturnRequestPayload) {
    return patch<ReturnRequest>({
        url: URL.RETURN_REQUEST_COMPLETE,
        pathParams: {
            id,
        },
    });
}

export default {
    createNewOrder,
    getOrderDetail,
    cancelOrder,
    addItemToOrder,
    deleteItemFromOrder,
    updateItemQuantity,
    updateItemDiscount,
    updateItemWishlist,
    updateOrder,
    getOrderAdjustments,
    updateVoucherDetail,
    createPayment,
    getPosSalesStats,
    createNewReturnRequest,
    getReturnRequest,
    cancelReturnRequest,
    completeReturnRequest,
    getReturnItems,
    createNewReturnItem,
    updateReturnItemQuantity,
    deleteReturnItem,
};
