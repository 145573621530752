import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { LOCAL_STORAGE_KEY } from '@/config/state.config';
import { useAuthStore } from '@/stores/auth.store';
import { useConstantsStore } from '@/stores/constants.store';
import { useCustomerStore } from '@/stores/customer.store';
import { useNewSaleStore } from '@/stores/newSale.store';
import { useOrdersStore } from '@/stores/orders.store';
import { useInventoryStore } from '@/stores/inventory.store';
import { useReturnRequestStore } from '@/stores/returnRequest.store';
import { useUIStore } from '@/stores/ui.store';
import { useWishlistStore } from '@/stores/wishlist.store';

const store = createPinia();
store.use(createPersistedState({
    key: (storeId) => `${LOCAL_STORAGE_KEY}_${storeId}`,
}));

export default store;
export {
    useAuthStore,
    useConstantsStore,
    useCustomerStore,
    useNewSaleStore,
    useOrdersStore,
    useInventoryStore,
    useReturnRequestStore,
    useUIStore,
    useWishlistStore,
};
